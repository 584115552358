<template>
  <v-card
    flat
    class="pa-3 mt-2">
    <h1>Заявка на вакансию</h1>
    <v-divider></v-divider>
    <v-card-text class="py-9 px-8">
      <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
        <div>
          <table>
            <tr>
              <td class="pe-6">
                ФИО:
              </td>
              <td>
                {{ name }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Должность:
              </td>
              <td>
                {{ vacancy }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Номер телефона:
              </td>
              <td>
                {{ phoneNumber }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Email:
              </td>
              <td>
                {{ email }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Дата рождения:
              </td>
              <td>
                {{ toDateSting(dateOfBirth) }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Гражданство:
              </td>
              <td>
                {{ citizenship }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Образование:
              </td>
              <td>
                {{ education }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Наличие медицинской книжки:
              </td>
              <td>
                {{ hasMedicalPassport ? 'Да' : 'Нет' }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Общий трудовой стаж:
              </td>
              <td>
                {{ seniority }}
              </td>
            </tr>
            <tr>
              <td class="pe-6">
                Комментарий:
              </td>
              <td>
                {{ comment }}
              </td>
            </tr>
          </table>
        </div>
        <div v-if="hasFile" class="mb-8 mb-sm-0">
          <v-icon
            @click="previewFile"
            size="30">
            {{ icons.mdiFilePdf }}
          </v-icon>
        </div>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text :v-if="statusId === 1">
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            cols="24"
            md="12"
          >
            <v-textarea
              v-model="reviewerComment"
              label="Комментарий"
              dense
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              @click="process"
              color="primary"
              class="me-3 mt-4"
            >
              Обработана
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="reject"
            >
              Отказать
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFormHandlers } from '@/helpers/forms'
import { toDateSting } from '@/helpers'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'
import {
  mdiFilePdf
} from '@mdi/js'

export default {
  name: 'VacancyApplicationForm',
  data: () => ({
    toDateSting: toDateSting,
    icons: {
      mdiFilePdf
    },
    rules
  }),
  computed: {
    ...mapFormHandlers('vacancyApplication/form', [
      'vacancy',
      'name',
      'phoneNumber',
      'citizenship',
      'email',
      'dateOfBirth',
      'education',
      'hasMedicalPassport',
      'seniority',
      'additionalInformation',
      'comment',
      'hasFile',
      'reviewerComment',
      'statusId',
      'creationTime',
    ]),
  },
  async created() {
    const { id } = this.$route.params
    await this.fetchById(id)
  },
  methods: {
    async previewFile(){
      const { id } = this.$route.params
      await this.getFile(id)
    },
    ...mapActions('vacancyApplication/form', ['fetchById', 'update', 'getFile']),
    async process() {
      if (this.$refs.form.validate()) {
        const { id } = this.$route.params
        await this.update({ id: id, comment: this.reviewerComment, statusId: 3 })
        this.$router.back()
      }
    },
    async reject() {
      if (this.$refs.form.validate()) {
        const { id } = this.$route.params
        await this.update({ id: id, comment: this.reviewerComment, statusId: 2 })
        this.$router.back()
      }
    },
  },
}
</script>
